import {ref} from "vue";
export type TService = {
    slug: string;
    title: string;
    cover: string;
}
export const services = ref([
    {
        slug: "diamond-drilling",
        title: "service.drilling.title",
        cover: "service-drilling.webp"
    },
    {
        slug: "diamond-cutting",
        title: "service.cutting.title",
        cover: "service-cutting.jpg"
    },
    {
        slug: "dismantling",
        title: "service.dismantling.title",
        cover: "service-dismantling.jpg"
    }
])